import farmSo from "stores/farm"
import growUnitSo from "stores/growUnit"
import { getGrowUnitName } from "stores/growUnit/utils"
import { getByPageId, isChild } from "stores/mainMenu/utils"
import locationSo from "stores/route/location"
import querySo from "stores/route/query"
import { PAGE_ID, URL_PAR } from "stores/route/utils/types"
import userSo from "stores/user"
import { COMPONENT_TYPE, Component, GrowUnitComponent } from "types/Service"



const isPLC = import.meta.env.VITE_TARGET == "plc"

/**
 * restituisce un URL a fronte di un PAGE-ID
 * @param pageId
 * @param id identificativo aggiunto utile nelle pagine di dettaglio
 * @param nestedId identificativo aggiunto utile nelle pagine con dettaglio annidato
 */
export function getUrlByPageId(pageId: PAGE_ID, id?: any, nestedId?: string): string {
    if (!pageId) return null

    // un po' brutto ma funziona! elimino il filtro txt
    const searchParams = new URLSearchParams(querySo.state.queryUrl)
    searchParams.delete(URL_PAR.TEXT)
    querySo.state.queryUrl = searchParams.toString()
    const q = "?" + querySo.state.queryUrl

    switch (pageId) {

        //#region FARMS

        case PAGE_ID.FARM_GROWUNITS:
            return buildFarmUrl(`/farms/{id}/growunits`, id)
        case PAGE_ID.FARM_PLANNER:
            return buildFarmUrl(`/farms/{id}/planner${q}`, id)
        case PAGE_ID.FARM_CYCLES:
            return buildFarmUrl(`/farms/{id}/cycles${q}`, id)
        case PAGE_ID.FARM_BI:
            return buildFarmUrl(`/farms/{id}/bi${q}`, id)
        case PAGE_ID.FARM_MAP:
            return buildFarmUrl(`/farms/{id}/map${q}`, id)

        case PAGE_ID.FARM_TASKS:
            return buildFarmUrl(`/farms/{id}/tasks${q}`, id)
        case PAGE_ID.FARM_AI_PLANNING:
            return buildFarmUrl(`/farms/{id}/productions${q}`, id)
        case PAGE_ID.FARM_ALERTS:
            return buildFarmUrl(`/farms/{id}/alerts${q}`, id)

        case PAGE_ID.FARM_PLC_ALERTS:
            return buildFarmUrl(`/farms/{id}/plc_alerts`, id)
        case PAGE_ID.COMPONENT_POWER_MONITOR:
            return buildFarmUrl(`/farms/{id}/POWERS/powmonitor`, id)

        case PAGE_ID.FARM_CHARTS:
            return buildFarmUrl(`/farms/{id}/charts`, id)
        case PAGE_ID.FARM_CONSOLES:
            return buildFarmUrl(`/farms/{id}/consoles`, id)
        case PAGE_ID.FARM_TECHNICAL_DASHBOARDS:
            return buildFarmUrl(`/farms/{id}/technical-dashboards`, id)
        case PAGE_ID.FARM_BUSINESS_DASHBOARDS:
            return buildFarmUrl(`/farms/{id}/business-dashboards`, id)
        case PAGE_ID.FARM_EXTERNAL_LINK:
            return buildFarmUrl(`/farms/{id}/external-link/${nestedId}`, id)

        //#endregion



        //#region GROWUNITS

        case PAGE_ID.GROWUNIT_TOWERS:
            return buildGrowUnitUrl(`/growunits/{id}/layers`, id)
        case PAGE_ID.GROWUNIT_RECIPE:
            return buildGrowUnitUrl(`/growunits/{id}/recipe`, id)

        case PAGE_ID.GROWUNIT_HOME:
            return buildComponentUrl(`/farms/{id}/{code}/home`, id, nestedId)
        case PAGE_ID.GROWUNIT_MAIN:
            return buildComponentUrl(`/farms/{id}/{code}/main`, id, nestedId)
        case PAGE_ID.GROWUNIT_LAYERS:
            return buildComponentUrl(`/farms/{id}/{code}/layers`, id, nestedId)
        case PAGE_ID.GROWUNIT_FERTI:
            return buildComponentUrl(`/farms/{id}/{code}/ferti`, id, nestedId)
        case PAGE_ID.GROWUNIT_ADJ:
            return buildComponentUrl(`/farms/{id}/{code}/adj`, id, nestedId)
        case PAGE_ID.GROWUNIT_LIGHTING:
            return buildComponentUrl(`/farms/{id}/{code}/lighting`, id, nestedId)
        case PAGE_ID.GROWUNIT_LIGHTING_PS:
            return buildComponentUrl(`/farms/{id}/{code}/lightingps`, id, nestedId)
        case PAGE_ID.GROWUNIT_IRRIGATION:
            return buildComponentUrl(`/farms/{id}/{code}/irrigation`, id, nestedId)
        case PAGE_ID.GROWUNIT_PLC_ALERTS:
            return buildComponentUrl(`/farms/{id}/{code}/plc_alerts`, id, nestedId)

        //#region COMPONENTS

        case PAGE_ID.COMPONENT_OSMOTIC_WATER:
            return buildComponentUrl(`/farms/{id}/{code}/ow`, id, nestedId)
        case PAGE_ID.COMPONENT_OSMOTIC_WATER_ALERTS:
            return buildComponentUrl(`/farms/{id}/OW/plc_alerts`, id, nestedId)

        case PAGE_ID.COMPONENT_HVAC:
            return buildComponentUrl(`/farms/{id}/{code}/hvac`, id, nestedId)
        case PAGE_ID.COMPONENT_HVAC_ALERTS:
            return buildComponentUrl(`/farms/{id}/HVAC/plc_alerts`, id, nestedId)

        case PAGE_ID.COMPONENT_GERMINATION:
            return buildComponentUrl(`/farms/{id}/{code}/germinator`, id, nestedId)
        case PAGE_ID.COMPONENT_GERMINATION_ALERTS:
            return buildComponentUrl(`/farms/{id}/GERM/plc_alerts`, id, nestedId)

        //#region PLC CONSOLE

        case PAGE_ID.FARM_MODULES:
            return buildFarmUrl(`/farms/{id}/modules`, id)
        case PAGE_ID.FARM_METADATA:
            return buildFarmUrl(`/farms/{id}/metadata`, id)

        //#endregion
        //#endregion
        //#endregion



        //#region LIBRARY

        case PAGE_ID.RECIPES:
            return `/recipes${id ? `/${id}` : ""}`
        case PAGE_ID.CROPS:
            return `/crops${id ? `/${id}` : ""}`
        case PAGE_ID.PRODUCTS:
            return `/products${id ? `/${id}` : ""}`

        //#endregion



        //#region SETTINGS

        case PAGE_ID.ALARMS:
            return `/alarms${id ? `/${id}` : ""}`

        //#endregion


        case PAGE_ID.PREFERENCES:
            return `/preferences`
        default:
            return `/${pageId}`
    }
}
function buildGrowUnitUrl(url: string, id: string): string {
    if (!id) id = growUnitSo.state.select?.id?.toString()
    return id ? url.replace("{id}", id) : null
}
function buildFarmUrl(url: string, id: number) {
    const idStr = isPLC
        ? "current"
        : (id ? id.toString() : farmSo.state.select?.id?.toString())
    return url.replace("{id}", idStr)
}
/** costruisce l'url del COMPONENT eventualmente valorizzando l'id  */
function buildComponentUrl(url: string, farmId: number, code?: string): string {
    url = buildFarmUrl(url, farmId)
    if (!code) code = farmSo.state.componentSelect?.code
    return code ? url.replace("{code}", code) : null
}


/** Restituisce un titolo in base alla pagina (tipicamente usato nell'header) */
export function getHeaderTitleByPageId(pageId: PAGE_ID): string {
    const component = farmSo.state.componentSelect
    if (isPLC) {
        return component?.name ?? "--"
        //if (pageId == PAGE_ID.FARM_GROWUNITS && !!component) return component.name
    } else {
        if (pageId == PAGE_ID.FARMS) {
            return farmSo.state.select?.name
        } else if (pageId == PAGE_ID.FARM_GROWUNITS) {
            return component?.name ?? "--"
            //return !!growUnitSo.state.select ? getGrowUnitName(growUnitSo.state.select) : component?.name
        }
    }
}


/** Restituisce l'url in base al COMPONENT-CODE specificato */
export function getUrlFromComponentCode(code: string): string {
    const component = farmSo.getComponentByCode(code)
    if (!component) return null
    const pageId = {
        [COMPONENT_TYPE.GROWUNIT]: PAGE_ID.GROWUNIT_HOME,
        [COMPONENT_TYPE.GERMINATOR]: PAGE_ID.COMPONENT_GERMINATION,
        [COMPONENT_TYPE.HVAC]: PAGE_ID.COMPONENT_HVAC,
        [COMPONENT_TYPE.POWERS]: PAGE_ID.COMPONENT_POWER_MONITOR,
        [COMPONENT_TYPE.OSMOTIC_WATER]: PAGE_ID.COMPONENT_OSMOTIC_WATER,
    }[component.type]
    return getUrlByPageId(pageId, farmSo.state.select.id, code)
}

/** 
 * url per la pagina di una specifica Farm 
 * se la pagina precedente era "child" della sezione FARM allora torna alla precedente altrimenti va in DETAIL
*/
export function getUrlFromFarmId(farmId: number): string {
    if (!farmId) return null

    // pagina di default
    let pageId = userSo.isDebugger() && userSo.plcAccess() ? PAGE_ID.FARM_MAP : PAGE_ID.FARM_GROWUNITS

    // la pagina attuale/precedente è nella sezione FARMS e allora prendi quella
    if (isChild(locationSo.state.currentPage, PAGE_ID.FARMS, true)) {
        pageId = locationSo.state.currentPage
    } else if (isChild(locationSo.state.previousPage, PAGE_ID.FARMS, true)) {
        pageId = locationSo.state.previousPage
    }

    return getUrlByPageId(pageId, farmId)
}


/** 
 * url per la pagina di una specifica GrowUnit 
 * se la pagina precedente era "child" della sezione GROWUNIT allora torna alla sezione precedente altrimenti va in DETAIL
*/
export function getUrlFromGrowUnitPage(growUnitId: number): string {
    if (!growUnitId) return null

    // pagina di default
    let pageId = PAGE_ID.GROWUNIT_TOWERS
    // la pagina attuale/precedente è nella sezione GROWUNITS e allora prendi quella
    if (isChild(locationSo.state.currentPage, PAGE_ID.FARM_GROWUNITS, true)) {
        pageId = locationSo.state.currentPage
    } else if (isChild(locationSo.state.previousPage, PAGE_ID.FARM_GROWUNITS, true)) {
        pageId = locationSo.state.previousPage
    }

    // se è TOWERS o RECIPE non c'e' bisogno del COMPONENT
    if (pageId == PAGE_ID.GROWUNIT_TOWERS || pageId == PAGE_ID.GROWUNIT_RECIPE) {
        return getUrlByPageId(pageId, growUnitId)
    }

    // ricavo il COMPONENT e vado...
    const farm = farmSo.state.select
    const component = farmSo.getComponentByGrowUnitCode(farmSo.getGrowUnit(growUnitId)?.growUnitCode)
    return getUrlByPageId(pageId, farm?.id, component?.code)
}

/** 
 * url per la pagina di uno specifico component 
 * se la pagina precedente era "child" della sezione GROWUNIT 
 * allora torna alla sezione precedente altrimenti va in HOME
*/
export function getUrlFromComponent(component: Component): string {

    const { parents, sections, index } = getByPageId(locationSo.state.currentPage) ?? {}
    const group = sections[index]?.group
    const code = component.code
    const farmId = farmSo.state.select?.id

    if (isChild(locationSo.state.currentPage, PAGE_ID.FARM_GROWUNITS, true) && component.type == group) {
        return getUrlByPageId(locationSo.state.currentPage, farmId, code)
    }
    if (isChild(locationSo.state.previousPage, PAGE_ID.FARM_GROWUNITS, true) && component.type == group) {
        return getUrlByPageId(locationSo.state.previousPage, farmId, code)
    }

    // sceglie la pagina di destinazione in base al tipo di COMPONENT
    if (!isPLC && component.type == COMPONENT_TYPE.GROWUNIT) {
        const guComponent = component as GrowUnitComponent
        const gu = farmSo.getGrowUnitByCode(guComponent.options.code)
        return getUrlByPageId(PAGE_ID.GROWUNIT_TOWERS, gu.id)
    }
    const pageId = {
        [COMPONENT_TYPE.GROWUNIT]: PAGE_ID.GROWUNIT_HOME,
        [COMPONENT_TYPE.GERMINATOR]: PAGE_ID.COMPONENT_GERMINATION,
        [COMPONENT_TYPE.HVAC]: PAGE_ID.COMPONENT_HVAC,
        [COMPONENT_TYPE.POWERS]: PAGE_ID.COMPONENT_POWER_MONITOR,
        [COMPONENT_TYPE.OSMOTIC_WATER]: PAGE_ID.COMPONENT_OSMOTIC_WATER,
    }[component.type] ?? PAGE_ID.GROWUNIT_HOME
    return getUrlByPageId(pageId, farmId, code)
}

