import { ArrowBack, ArrowUpward } from "@mui/icons-material"
import { SxProps } from "@mui/material"
import { Box } from "@mui/system"
import { useStore } from "@priolo/jon"
import { FunctionComponent, useMemo } from "react"
import farmSo from "stores/farm"
import growUnitSo from "stores/growUnit"
import { getGrowUnitName } from "stores/growUnit/utils"
import drawerSo from "stores/mainMenu/drawer"
import { MenuItem } from "stores/mainMenu/utils"
import { getHeaderTitleByPageId } from "stores/route/utils/pagesId"
import { PAGE_ID } from "stores/route/utils/types"
import theme from "styles/theme"



const isPLC = import.meta.env.VITE_TARGET == "plc"

interface Props {
	item: MenuItem
	index?: number
	onClick: () => void,
}

const ParentItem: FunctionComponent<Props> = ({
	item,
	index,
	onClick,
}) => {

	// STORE
	const farmSa = useStore(farmSo)
	const growUnitSa = useStore(growUnitSo)
	const drawerSa = useStore(drawerSo)

	// HOOKs
	const title = useMemo(() => getHeaderTitleByPageId(item.id), [farmSa, growUnitSa, item.id])

	// HANDLER
	const handleClick = () => {
		onClick()
	}

	// RENDER
	const textUp = item.title
	const icon = index == 0 ? <ArrowBack /> : <ArrowUpward />

	return (
		<Box sx={sxContainer} data-test={`menu-parent-${item.id}`}
			onClick={handleClick}
		>

			<Box sx={sxIcon}>
				{icon}
			</Box>

			<Box sx={sxTextContainer(drawerSa.isCompact)}>
				{textUp && (
					<Box sx={sxTextUp}>
						{textUp}
					</Box>
				)}
				{title && (
					<Box sx={sxTextDown}>
						{title}
					</Box>
				)}
			</Box>

		</Box>
	)
}

export default ParentItem

const sxContainer: SxProps = {
	margin: "8px 7px 0px 10px",
	display: "flex",
	cursor: "pointer",
	fill: theme.palette.text.primary,
}

const sxIcon: SxProps = {
	width: 24,
	height: 24,
}

const sxTextContainer = (compact: boolean): SxProps => ({
	display: "flex",
	flexDirection: "column",
	opacity: compact ? 0 : 1,
	transition: theme.transitions.create(["opacity"]),
})

const sxTextUp: SxProps = {
	marginBottom: "-6px",
	fontSize: 14,
	fontWeight: 500,
}

const sxTextDown: SxProps = {
	opacity: 0.7,
	fontWeight: 100,
	fontSize: 12,
}
