import { Menu as MenuIcon } from "@mui/icons-material";
import { Box, IconButton, SxProps } from "@mui/material";
import { useStore } from "@priolo/jon";
import NatsCnnIcon from "components/NatsCnnIcon";
import TitleCmp from "components/header/TitleCmp";
import SearchFiltersUrl from "components/search/SearchFiltersUrl";
import AlertFilterHeader from "pages/farms/alert/list/FilterHeader";
import PlannerFilterHeader from "pages/farms/planner/FilterHeader";
import ProductionFilterHeader from "pages/farms/productions/FilterHeader";
import NotifyButton from "pages/root/NotifyButton";
import { FunctionComponent } from "react";
import farmSo from "stores/farm";
import drawerSo from "stores/mainMenu/drawer";
import { isChild } from "stores/mainMenu/utils";
import locationSo from "stores/route/location";
import { PAGE_ID, URL_PAR } from "stores/route/utils/types";
import palette from "styles/palette";
import theme from "styles/theme";
import UserButton from "../user/UserButton";
import SubtitleCmp from "./SubtitleCmp";
import FilterHeader from "pages/recipes/FilterHeader";
import MetaFilterHeader from "pages/plc/metadata/FilterHeader";
import LightFilterHeader from "pages/plc/main/subsystems/lighting/FilterHeader";
import IrriFilterHeader from "pages/plc/main/subsystems/irrigation/FilterHeader";



const isPLC = import.meta.env.VITE_TARGET == "plc"

const PageHeader: FunctionComponent = () => {

	// STORE
	const locationSa = useStore(locationSo)
	const drawerSa = useStore(drawerSo)

	// HOOKs

	// HANDLERS
	const handleMenuClick = () => drawerSo.toggleOpen()

	// RENDER
	const currentPageId = locationSa.currentPage
	const isNatsVisible = isPLC || (isChild(currentPageId, PAGE_ID.FARMS) && farmSo.getHavePLCAccess())

	return (
		<Box sx={cssRoot}>

			<IconButton
				onClick={handleMenuClick}
				disabled={drawerSa.isOpen}
			>
				<MenuIcon sx={[{ transition: "width 700ms ease" }, drawerSa.isOpen && { width: 0 }]} />
			</IconButton>

			{/* TITLE */}
			<Box sx={{ display: "flex", flexDirection: "column", ml: 2, gap: "2px" }}>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<TitleCmp />
					{isNatsVisible && <NatsCnnIcon />}
				</Box>
				<HeaderSubtitle pageId={currentPageId} />
			</Box>

			<Box sx={{ flex: "1 0 0" }} />

			<HeaderFilter pageId={currentPageId} />

			{!isPLC && (<NotifyButton sx={{ mx: 1 }} />)}

			<UserButton sx={{ mx: 1 }} />

		</Box>
	)
}

export default PageHeader


const cssRoot = {
	display: "flex", justifyContent: "center", alignItems: "center",
	minHeight: theme.app.header.height,
	pr: 4,
	pl: 2,
	color: palette.text.primary,
	bgcolor: theme.palette.background.header,
	zIndex: theme.app.header.zIndex,
}



const PLANNER_PAGES: PAGE_ID[] = [PAGE_ID.FARM_PLANNER, PAGE_ID.FARM_CYCLES, PAGE_ID.FARM_TASKS, PAGE_ID.FARM_BI]


const HeaderSubtitle: FunctionComponent<{ pageId: PAGE_ID }> = ({
	pageId
}) => {
	if (PLANNER_PAGES.includes(pageId)) return <PlannerFilterHeader />
	if (pageId == PAGE_ID.RECIPES) return <FilterHeader sx={{ ml: "-8px" }} />
	if (pageId == PAGE_ID.CROPS) return <FilterHeader sx={{ ml: "-8px" }} />
	if (pageId == PAGE_ID.PRODUCTS) return <FilterHeader noFarms sx={{ ml: "-8px" }} />
	if (pageId == PAGE_ID.FARM_ALERTS) return <AlertFilterHeader />
	if (pageId == PAGE_ID.FARM_AI_PLANNING) return <ProductionFilterHeader />
	if (pageId == PAGE_ID.FARM_METADATA) return <MetaFilterHeader />
	if (pageId == PAGE_ID.GROWUNIT_LIGHTING) return <LightFilterHeader />
	if (pageId == PAGE_ID.GROWUNIT_IRRIGATION) return <IrriFilterHeader />
	return <SubtitleCmp sx={{ opacity: 0.8, mr: 1 }} />
}

const HeaderFilter: FunctionComponent<{ pageId: PAGE_ID, sx?: SxProps }> = ({
	pageId,
	sx
}) => {
	return {
		[PAGE_ID.FARMS]: <SearchFiltersUrl />,
		[PAGE_ID.FARM_CYCLES]: <SearchFiltersUrl showFilterButton />,
		[PAGE_ID.FARM_TASKS]: <SearchFiltersUrl />,
		[PAGE_ID.FARM_ALERTS]: <SearchFiltersUrl paramUrl={URL_PAR.ALERT_TEXT} />,
		[PAGE_ID.FARM_PLC_ALERTS]: <SearchFiltersUrl sx={{ flex: 1 }} placeholder='search by Code and Description'/>,
		[PAGE_ID.GROWUNIT_PLC_ALERTS]: <SearchFiltersUrl sx={{ flex: 1 }} placeholder='search by Code and Description'/>,
		[PAGE_ID.COMPONENT_HVAC_ALERTS]: <SearchFiltersUrl sx={{ flex: 1 }} placeholder='search by Code and Description'/>,
		[PAGE_ID.COMPONENT_OSMOTIC_WATER_ALERTS]: <SearchFiltersUrl sx={{ flex: 1 }} placeholder='search by Code and Description'/>,
		[PAGE_ID.COMPONENT_GERMINATION_ALERTS]: <SearchFiltersUrl sx={{ flex: 1 }} placeholder='search by Code and Description'/>,
		[PAGE_ID.FARM_GROWUNITS]: <SearchFiltersUrl />,
		[PAGE_ID.FARM_AI_PLANNING]: <SearchFiltersUrl paramUrl={URL_PAR.PRODUCTION_SEARCH} />,
		[PAGE_ID.RECIPES]: <SearchFiltersUrl />,
		[PAGE_ID.CROPS]: <SearchFiltersUrl />,
		[PAGE_ID.PRODUCTS]: <SearchFiltersUrl />,
		[PAGE_ID.ALARMS]: <SearchFiltersUrl />,
		[PAGE_ID.ROOT]: <SearchFiltersUrl showFilterButton />,
		[PAGE_ID.FARM_METADATA]: <SearchFiltersUrl sx={{ flex: 1 }} placeholder='search by Name, Alias, Target, Description. Use " for full phrase'/>,
	}[pageId as string]
}