import { Box, Divider, SxProps } from "@mui/material"
import { useStore } from "@priolo/jon"
import { FunctionComponent, useMemo } from "react"
import { useHistory } from "react-router-dom"
import parentsSo from "stores/mainMenu/parents"
import { MenuItem } from "stores/mainMenu/utils"
import { getUrlByPageId } from "stores/route/utils/pagesId"
import ParentItem from "./ParentItem"
import theme from "styles/theme"



interface Props {
	sx: any
}

const MenuParents: FunctionComponent<Props> = ({
	sx
}) => {

	// STORE
	const parentsSa = useStore(parentsSo)

	// HOOKs
	const history = useHistory()

	// HANDLERs
	const handleClick = (item: MenuItem) => {
		const url = getUrlByPageId(item.id)
		if (url) history.push(url)
	}

	// RENDER
	// tolgo i primo parent perche' la root non la visualizzo
	const items = parentsSa.items?.slice(1) ?? []
	if (!(items?.length > 0)) return null
	const cnContainer = [sxContainer, { height: `${parentsSa.height}px` }, sx]

	return <Box sx={cnContainer}>
		<Box sx={sxList}>
			{items.map((item, index) => (
				<ParentItem key={item.id.toString()}
					index={index}
					item={item}
					onClick={() => handleClick(item)}
				/>
			))}
		</Box>
		{/* <Divider sx={sxDivider} /> */}
	</Box>
}

export default MenuParents

const sxContainer: SxProps = {
	overflow: "hidden",
	transition: theme.transitions.create(['height']),
}

const sxList: SxProps = {
	display: "flex",
	flexDirection: "column",
}

// const sxDivider: SxProps = {
// 	margin: "5px 16px 20px 16px",
// 	transition: theme.transitions.create(['opacity', 'margin'])
// }
