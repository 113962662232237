import dayjs from "dayjs";
import humps from "humps";
import { createUUID } from "mocks/data/utils";
import { connect, ConnectionOptions, DebugEvents, Empty, Events, NatsConnection, StringCodec, Subscription } from "nats.ws";
import natsSo, { NATS_STATUS } from "stores/nats";
import userSo from "stores/user";
import valueSo from "stores/values";
import { Nats } from "types/Service";



/** opzioni di connessione */
let options: ConnectionOptions = null
let responseTimeout: number = 10000
/** la connessione a NATS */
let nc: NatsConnection = null
/** numero di tentativi di riconnessione */
let attempts = 0
/** id timeout per la riconnessione */
let reconnectTimeout = null
/** indic che non si deve riconnettere */
let reconnectDisabled = false
/** l'oggetto delle subscription */
let sub: Subscription
/** ultimo host a cui s'e' connesso (se non connesso = null) */
let lastHostId: string

/** dove sono pubblicati i COMMAND vale a dire le modifiche da parte del FE*/
let publishSubjectTemplate = null
/** sctringa di sottoscrizione */
let subscribeSubject = null
/** indicates the subject to use in NATS to receive metadata */
export const METADATA_SUBJECT = import.meta.env.VITE_NATS_METADATA_SUBJECT ?? "services.plc-console.req.metadata.get"

let callbackConnection: () => void = null



/** inizia una connessione con NATS */
export async function NATSConnect(
	nats: Nats,
	onConnection?: () => void
) {
	const host = nats.host ?? window.location.hostname
	const prot = (nats.enableTls === true || window.location.protocol === "https:") ? "wss:" : "ws:"
	const url = `${prot}//${host}:${nats.port ?? 8022}`
	const hostId = `${url}@${nats.username ?? ""}`
	if (hostId == lastHostId) return

	subscribeSubject = nats.subscribe
	publishSubjectTemplate = nats.publish
	callbackConnection = onConnection

	options = {
		name: `PLC-CONSOLE ${createUUID()}`,
		servers: url,
		user: nats.username,
		pass: nats.password,
		reconnect: true,
		reconnectTimeWait: 1000,
		maxReconnectAttempts: -1,
		timeout: 3000,
		maxPingOut: 3,
		pingInterval: 3000,
		...nats.username ? { inboxPrefix: `_INBOX.${nats.username}` } : {}
	}
	if (!!nats.responseTimeout) responseTimeout = nats.responseTimeout

	// se sono in dev preferisco non avere questi limiti
	if (import.meta.env.DEV) {
		options.timeout = undefined
		options.maxPingOut = undefined
		options.pingInterval = undefined
	}
	/** provo a connettermi */
	if (!!nc) await NATSClose()
	attempts = 0
	reconnectDisabled = false
	tryConnect()
}


/** prova a connettersi. se non riesce ci riprova */
async function tryConnect() {

	if (!!nc) await NATSClose()
	tryConnectStop()
	if (reconnectDisabled) return

	try {
		log("NATS", `try connect::${attempts}::servers::${options.servers}`)
		nc = await connect(options)
	} catch (err) {
		nc = null
		attempts++
		natsSo.changeStatus(attempts > 5 ? NATS_STATUS.OFFLINE : NATS_STATUS.RETRY)
		reconnectTimeout = setTimeout(tryConnect, options.reconnectTimeWait)
		return
	}

	// START SIMULATOR
	if (import.meta.env.DEV && import.meta.env.VITE_NATS_MOCK == "true") {
		const { start } = await import("./mock/simulator")
		start(nc)
	}

	attempts = 0
	reconnectDisabled = false
	lastHostId = `${options.servers[0] as string}@${options.user ?? ""}`
	natsSo.changeStatus(NATS_STATUS.ONLINE)

	callbackConnection?.()
	callbackConnection = null

	listenerStatus()
	listenerClosed()
	listenerSubscribe()
}

/** annulla l'ultimo TRY-CONNECTION */
function tryConnectStop() {
	if (!!reconnectTimeout) clearTimeout(reconnectTimeout)
}

/**chiamata su connessione avvenuta determina lo stato della connessione DOPO che NATS si è collegato correttamente */
async function listenerStatus() {
	
	log("NATS", `connected::${options.name}::server::${nc.getServer()}`);
	valueSo.state.services = {}
	valueSo.buffer = {}

	for await (const s of nc.status()) {
		if (s.type == DebugEvents.Reconnecting) {
			attempts++
			natsSo.changeStatus(attempts > 5 ? NATS_STATUS.OFFLINE : NATS_STATUS.RETRY)
			log("NATS", `try connect::${attempts}::servers::${options.servers}`)
		} else if (s.type == Events.Reconnect) {
			attempts = 0
			natsSo.changeStatus(NATS_STATUS.ONLINE)
			log("NATS", `re-connected::${options.name}::server::${nc.getServer()}`);
		}
	}
}

/** il canale NATS chiude */
async function listenerClosed() {
	const err = await nc.closed()
	nc = null
	if (err) {
		console.error('[NATS]::connection error::', err);
	} else {
		log("NATS", 'closed::OK')
		natsSo.setStatus(NATS_STATUS.OFFLINE)
		valueSo.setServices({})
	}
}

/** ricevo un messaggio da NATS */
async function listenerSubscribe() {
	sub = nc.subscribe(subscribeSubject)
	for await (const m of sub) {
		const subject = m.subject
		const serviceCode = subject.split(".")[1]
		try {
			let values = m.json() as { [key: string]: number }
			valueSo.updateValues({ serviceCode, values })
		} catch (error) {
			let msg = m.string()
			console.error(msg)
			console.error(error?.chainedError?.message)
		}
	}
}

/**
 * pubblico un "value" in un service_code + alias diretto alla coda NATS (e quindi al PLC)
 * Per quanto riguarda i "sinottici" il serviceCode corrisponde a: `<GrowUnit>.growUnitCode`
 */
export function NATSPublish(serviceCode: string, alias: string, value: any) {
	if (!userSo.plcAccess()) return false
	if (!alias || value == null || value.length == 0) return false
	if (!serviceCode) serviceCode = valueSo.state.serviceCodeSel

	if (typeof value == "boolean") value = value ? 1 : 0
	const json = `{"${alias}": ${value.toString()}}`

	log("NATS", `publish::${serviceCode}`, json)

	const subject = publishSubjectTemplate.replace(/\*/g, serviceCode)
	nc.publish(subject, json)
	return true
}

/**
 * Effettua una REQUEST tramite NATS
 */
export async function NATSRequest(subject: string, data?: any) {
	log("NATS", `request::subject:${subject}`)
	const msg = await nc.request(
		subject,
		data ?? Empty,
		{ timeout: responseTimeout }
	)
	const sc = StringCodec()
	const resp = humps.camelizeKeys(JSON.parse(sc.decode(msg.data)))
	log("NATS", "request::response:", resp)
	return resp
}


/** chiudo tutto! */
export async function NATSClose() {
	reconnectDisabled = true
	tryConnectStop()

	// STOP SIMULATOR
	if (import.meta.env.DEV && import.meta.env.VITE_NATS_MOCK == "true") {
		const { stop } = await import("./mock/simulator")
		stop()
	}

	await nc?.close()
	nc = null
	lastHostId = null
	log("NATS", "::connection deleted")
}


function log(tag: string, msg: string, data?: any) {
	console.info(
		`%c[${tag}]::[${dayjs().format('HH:mm:ss.SSS')}]%c::${msg}`,
		'color: yellow;',
		'',
	)
	if (data !== undefined) console.info(data);
}