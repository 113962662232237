import { ArrowDropDown, Clear as ClearIcon, OpenInNew, Search as SearchIcon } from "@mui/icons-material";
import { Box, IconButton, SxProps } from "@mui/material";
import React, { ForwardRefRenderFunction, forwardRef, useState } from "react";
import HelperTextCmp from "./HelperTextCmp";



export interface WrapControlProps {
	/** tasto da visualizzare se ci sono errori */
	helperText?: string
	/** è in sola lattura e inoltre */
	disabled?: boolean
	/** non si puo' cliccare */
	readOnly?: boolean

	/** un testo da visualizzare */
	textValue?: string
	/** testo da visualizzare se non ci sono contenuti */
	placeholder?: string

	refIcon?: any
	/** tipo di icona da visualizzare a destra */
	variantIcon?: ICON_VARIANT

	onClick?: (e: React.MouseEvent) => void
	/** indica se posso eliminare il contenuto */
	onClickClear?: (e: React.MouseEvent) => void

	sx?: SxProps
	/** se non c'e' "textValue" visualizza questo */
	children?: React.ReactNode
}

export enum ICON_VARIANT {
	PAGE = "page",
	ARROW = "arrow",
	NONE = "none",
	FIND = "find"
}

const WrapControl: ForwardRefRenderFunction<any, WrapControlProps> = ({
	helperText,
	disabled,
	readOnly,

	textValue,
	placeholder,

	refIcon,
	variantIcon = ICON_VARIANT.NONE,

	onClick,
	onClickClear,

	children,
	sx,
}, ref) => {

	// HOOKs
	const [cancelVisible, setCancelVisible] = useState(false)

	// HANDLERs
	const handleClick = (e: React.MouseEvent) => {
		if (!readOnly && !disabled) onClick?.(e)
		e.preventDefault()
	}
	const handleClear = (e: React.MouseEvent) => {
		e.stopPropagation()
		onClickClear?.(e)
	}

	// RENDER

	return (
		<Box sx={[sxRoot(readOnly || disabled), sx as any]}
			onClick={handleClick}
			onMouseEnter={() => setCancelVisible(true)}
			onMouseLeave={() => setCancelVisible(false)}
			ref={ref}
		>
			<Content textValue={textValue} children={children} placeholder={placeholder} />

			{(textValue || children) && onClickClear && cancelVisible && !readOnly && !disabled && (
				<Box sx={sxClearBtt}
					onClick={handleClear}
				>
					<ClearIcon />
				</Box>
			)}

			{!readOnly && variantIcon != ICON_VARIANT.NONE && (
				<IconButton sx={{ mr: "-5px", alignSelf: "start" }}
					ref={refIcon}
					disabled={disabled}
				>
					<Icon disabled={disabled} variantIcon={variantIcon} />
				</IconButton>
			)}

			<HelperTextCmp text={helperText} sx={{ left: "13px", top: "25px" }} />
		</Box>
	)
}

export default forwardRef(WrapControl)

const sxRoot = (disabled: boolean): SxProps => ({
	cursor: !disabled ? "pointer" : "default",
	display: "flex", position: "relative", alignItems: "center",
	minHeight: "36px",
	p: "0px 7px 0px 14px",
	borderRadius: "10px",
	bgcolor: "background.down1",
	userSelect: "none",
})



function Icon({ disabled, variantIcon }) {
	if (disabled) return null
	return {
		[ICON_VARIANT.PAGE]: <OpenInNew />,
		[ICON_VARIANT.ARROW]: <ArrowDropDown />,
		[ICON_VARIANT.FIND]: <SearchIcon />
	}[variantIcon] ?? null
}

function Content({ textValue, children, placeholder }) {

	if (textValue != null && textValue.length > 0) {
		return <Box sx={sxText}>{textValue}</Box>
	}

	if (children != null) {
		return <Box sx={sxContent}>
			{children}
		</Box >
	}

	if (placeholder != null) {
		return <Box sx={sxPlaceholder}>{placeholder}</Box>
	}

	return <Box sx={{ flex: 1 }} />
}

const sxText: SxProps = {
	fontSize: 14,
	fontWeight: 300,
	flex: 1,
	overflowX: "hidden",
	whiteSpace: "nowrap",
	textOverflow: "ellipsis",
}
const sxContent: SxProps = {
	fontSize: 14,
	fontWeight: 300,
	flex: 1,
	overflowX: "hidden",
	display: "flex",
}
const sxPlaceholder: SxProps = {
	...sxText,
	opacity: .5,
}

const sxClearBtt: SxProps = {
	p: "5px",
	display: "flex",
	position: "absolute",
	right: "28px",
	bgcolor: "background.paper",
	cursor: "pointer",
	borderRadius: '10px'
}

