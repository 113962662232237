import { Box, LinearProgress } from '@mui/material';
import { useStore } from '@priolo/jon';
import Err from 'components/layout/ErrorBoundary';
import CycleTablePag from "pages/farms/cycles/TablePag";
import TasksListPag from "pages/farms/tasks/list/Pag";
import ChatDrawer from "pages/root/ChatDrawer";
import { FunctionComponent, Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import layoutSo from 'stores/layout';
import userSo from 'stores/user';
import PageHeader from '../header/Header';
import ContextMenu from "./ContextMenu";
import PageFooter from './Footer';
import LoadingCmp from "./LoadingCmp";
import SuspenseMsgCmp from "./SuspenseMsgCmp";
import MenuDrawer from "./menu/MenuDrawer";
import grafanaSo from 'stores/grafana';
import GrafanaDrawer from 'components/grafana/GrafanaDrawer';

const AlarmsTablePag = lazy(() => import('pages/alarms/list/TablePag'));
const FarmExternalLinksTable = lazy(() => import('pages/farms/externalLinks/TablePag'));
const FarmExternalLinksDetail = lazy(() => import('pages/farms/externalLinks/DetailPage'));
const FarmChartsPag = lazy(() => import('pages/farms/externalLinks/FarmChartsPag'));
const FarmPlannerPag = lazy(() => import('pages/farms/planner/PlannerPag'));
const FarmsListPag = lazy(() => import('pages/farms/list/ListPag'));
const FarmAlertPag = lazy(() => import('pages/farms/alert/list/Pag'));
const FarmProductionsPag = lazy(() => import('pages/farms/productions/Pag'));
const FarmBiPag = lazy(() => import('pages/farms/bi/Pag'));
const FarmMapPag = lazy(() => import('pages/farms/map/Pag'));

// COMPONENTS AND GROW-UNITS
const ComponentsListPag = lazy(() => import('pages/plc/services/ListPag'));

const GrowUnitLayers = lazy(() => import('pages/growUnits/layers/Pag'));
const UserPreferencesPag = lazy(() => import("components/user/PreferencesPag"));
const AlarmDetailsPag = lazy(() => import('pages/alarms/cards/DetailsPag'));
const CropDetailsPag = lazy(() => import('pages/crops/detail/Pag'));
const CropsTablePag = lazy(() => import('pages/crops/list/Pag'));
const GrowUnitRecipePag = lazy(() => import('pages/growUnits/recipe/RecipePag'));
const ProductDetailsPag = lazy(() => import('pages/products/cards/DetailsPag'));
const ProductsTablePag = lazy(() => import('pages/products/list/TablePag'));
const RecipeDetailsPag = lazy(() => import('pages/recipes/detail/DetailsPag'));
const RecipesTablePag = lazy(() => import('pages/recipes/list/Pag'));
const ZWDetailPag = lazy(() => import('pages/zw/DetailPag'));
const RootPage = lazy(() => import("pages/root/RootPage"));

//#region PLC

const HomePage = lazy(() => import("pages/plc/main/home/Pag"));
const OsmoticWaterPage = lazy(() => import("pages/plc/main/subsystems/osmoticWater/Pag"));
const SubMainPage = lazy(() => import("pages/plc/main/submain/Pag"));
const FertiPage = lazy(() => import("pages/plc/main/subsystems/actions/Pag"));
const LayersPage = lazy(() => import('pages/plc/main/subsystems/layers/Pag'));
//const ModulePage = lazy(() => import("pages/plc/module/Pag"));
//const MetadataPage = lazy(() => import("pages/plc/metadata/Pag"));
const AdjPage = lazy(() => import('pages/plc/main/subsystems/adj/Pag'));
const LightingPage = lazy(() => import('pages/plc/main/subsystems/lighting/Pag'));
const LightingPsPage = lazy(() => import('pages/plc/main/subsystems/lightingPs/Pag'));
const FarmAlertsPage = lazy(() => import('pages/plc/main/subsystems/alert/FarmAlertsPag'));
const ComponentAlertsPage = lazy(() => import('pages/plc/main/subsystems/alert/ComponentAlertsPag'));
const IrrigationPage = lazy(() => import('pages/plc/main/subsystems/irrigation/Pag'));
const HvacPage = lazy(() => import('pages/plc/main/subsystems/hvac/Pag'));
const GerminatorPage = lazy(() => import('pages/plc/main/subsystems/germinator/Pag'));
const PowerMonitorPag = lazy(() => import('pages/plc/main/subsystems/powerMonitor/Pag'));

//#endregion



const MainOS: FunctionComponent = () => {

	// STORE
	const layoutSa = useStore(layoutSo)
	const grafanaSa = useStore(grafanaSo)
	useStore(userSo)

	// HOOKS
	
	// RENDER
	return <Box sx={cssApp}>

		<MenuDrawer sx={cssLeftSide}/>

		<Box sx={cssRightSide} >

			<PageHeader />
			
			{layoutSa.busy ? <LinearProgress /> : <Box sx={{ mt: "4px" }} />}

			<Box sx={cssBoxScroller}>
				<Suspense fallback={<SuspenseMsgCmp />}>
					<Err>
						<Switch>

							{/* FARM */}

							<Route path="/farms/:id/map" component={FarmMapPag} />
							<Route path="/farms/:id/growunits" component={ComponentsListPag} />
							<Route path="/farms/:id/planner" component={FarmPlannerPag} />

							<Route path="/farms/:id/productions" component={FarmProductionsPag} />
							<Route path="/farms/:id/tasks" component={TasksListPag} />
							<Route path="/farms/:id/cycles" component={CycleTablePag} />
							<Route path="/farms/:id/alerts" component={FarmAlertPag} />
							<Route path="/farms/:id/plc_alerts" component={FarmAlertsPage} />
							<Route path="/farms/:id/:code/powmonitor" component={PowerMonitorPag} />

							<Route path="/farms/:id/bi" component={FarmBiPag} />
							<Route path="/farms/:id/charts" component={FarmChartsPag} />
							<Route path="/farms/:id/technical-dashboards" component={FarmExternalLinksTable} />
							<Route path="/farms/:id/business-dashboards" component={FarmExternalLinksTable} />
							<Route path="/farms/:id/consoles" component={FarmExternalLinksTable} />
							<Route path="/farms/:id/external-link/:linkId" component={FarmExternalLinksDetail} />



							{/* GROW-UNIT */}

							<Route path="/growunits/:id/layers" component={GrowUnitLayers} />
							<Route path="/growunits/:id/recipe" component={GrowUnitRecipePag} />

							<Route path="/farms/:id/:code/home" component={HomePage} />
							<Route path="/farms/:id/:code/main" component={SubMainPage} />
							<Route path="/farms/:id/:code/layers" component={LayersPage} />
							<Route path="/farms/:id/:code/ferti" component={FertiPage} />
							<Route path="/farms/:id/:code/adj" component={AdjPage} />
							<Route path="/farms/:id/:code/lighting" component={LightingPage} />
							<Route path="/farms/:id/:code/lightingps" component={LightingPsPage} />
							<Route path="/farms/:id/:code/irrigation" component={IrrigationPage} />
							<Route path="/farms/:id/:code/plc_alerts" component={ComponentAlertsPage} />

							<Route path="/farms/:id/:code/ow" component={OsmoticWaterPage} />
							
							<Route path="/farms/:id/:code/hvac" component={HvacPage} />

							<Route path="/farms/:id/:code/germinator" component={GerminatorPage} />



							{/* LIBRARY */}

							<Route exact path="/recipes" component={RecipesTablePag} />
							<Route path={["/recipes/new", "/recipes/:id"]} component={RecipeDetailsPag} />

							<Route exact path="/crops" component={CropsTablePag} />
							<Route path={["/crops/new", "/crops/:id"]} component={CropDetailsPag} />

							<Route exact path="/products" component={ProductsTablePag} />
							<Route path={["/products/new", "/products/:id"]} component={ProductDetailsPag} />



							{/* SETTINGS */}

							<Route exact path="/alarms" component={userSo.isAdmin() ? AlarmsTablePag : null} />
							<Route path={["/alarms/new", "/alarms/:id"]} component={userSo.isAdmin() ? AlarmDetailsPag : null} />

							<Route exact path="/zw" component={ZWDetailPag} />
							<Route path="/dashboard" component={RootPage} />



							{/* ONLY DEBUG */}

							<Route exact path="/farms" component={FarmsListPag} />

							{/* PANNELLE PREFERENCE USER */}

							<Route exact path="/preferences" component={UserPreferencesPag} />

							<Route path={["/", "*"]}>
								<Redirect to="/landingpage" />
							</Route>

						</Switch>
					</Err>
				</Suspense>
			</Box>

			<ContextMenu />

			<PageFooter />

			<LoadingCmp />

			{grafanaSa.open && <Box sx={{ height: grafanaSa.heigth + 36}} /> }

		</Box>

		<ChatDrawer />

		<GrafanaDrawer />

	</Box>
}

export default MainOS


const cssApp = {
	display: "flex",
	height: "100%",
}

const cssLeftSide = {
	display: "flex", 
	flexDirection: "column", 
	flex: "0 1",
	height: "100%",
}

const cssRightSide = {
	display: "flex", 
	flexDirection: "column", 
	flex: "1 0",
	overflowX: "hidden",
}

const cssBoxScroller = {
	flex: "1",
	overflowY: "auto", 
	overflowX: "auto",
}
