import { createStore, StoreCore } from "@priolo/jon";
import i18n from "i18next";
import { NATSPublish } from "plugins/nats";
import dialogSo, { DIALOG_TYPE } from "stores/layout/dialogStore";
import metaSo from "stores/metadata";
import { formatValue } from "stores/metadata/utils";
import natsSo, { NATS_STATUS } from "stores/nats";
import { Metadata } from "types/Metadata";
import { ServiceNATS, Values } from "types/Value";



export type AliasCode = {
	alias: string,
	code?: string,
	decimals?: number
}

const SET_VALUES_TIMEOUT = 1000

const setup = {

	state: {
		/** tutti i SERVICE NATS che sto ascoltando per ricevere i VALUES */
		services: <{ [code: string]: ServiceNATS }>{},
		/** il codice del service attualmente selezionato */
		serviceCodeSel: <string>null,
		/** le variabili del service code selezionato  */
		serviceSel: <ServiceNATS>null,
		/** il METADATA selezionato che indica dove indirizzare il ValueSelect */
		metadataSel: <Metadata>null,
		/** il value attualmente selezionato da inviare */
		valueSel: <any>null,
		/** indica se un DETAIL di un VALUE è aperto o no */
		isValuesOpen: false,
	},

	getters: {
		/** restituisco il VALUE di un METADATA */
		getByMetadata(metadata: Metadata, store?: ValueStore) {
			if (!metadata) return null
			return store.state.services[metadata.serviceCode]?.values[metadata.name]
		},
		/** restituisce il valore generico di un METADATA con "alias" e "code" */
		getByAlias(pl: AliasCode, store?: ValueStore) {
			if (!pl || !pl.alias) return null
			if (!pl.code) pl.code = store.state.serviceCodeSel
			return store.state.services[pl.code]?.values[pl.alias]
		},
		/** restituisce il valore `boolean` di un METADATA con "alias" e "code" */
		getBoolByAlias(p: AliasCode, store?: ValueStore): boolean {
			return store.getByAlias(p) == 1
		},
		/** restituisce il valore `number` di un METADATA con "alias" e "code" */
		getNumberByAlias(p: AliasCode, store?: ValueStore): number {
			const value = store.getByAlias(p)
			return typeof value === 'number' && !isNaN(value) ? value : null
		},
		/** restituisce il valore `number` in formato `string` (con numero decimali) di un METADATA con "alias" e "code" */
		getNumberFixByAlias(p: AliasCode, store?: ValueStore): string {
			const value = store.getNumberByAlias(p)
			return value?.toFixed(p.decimals) ?? "--"
		},

		/** restituisce il valore `number` di un METADATA con "alias" e codice del service selezionato */
		getNumber(alias: string, store?: ValueStore): number {
			const value = store.state.serviceSel?.values[alias]
			return typeof value === 'number' && !isNaN(value) ? value : null
		},
		/** restituisce il valore `number` in formato `string` di un METADATA con "alias" e codice del service selezionato */
		getNumberFormat(alias: string, store?: ValueStore): string {
			const service = store.state.serviceSel
			if (!service) return null
			const value = service.values[alias]
			const meta: Metadata = metaSo.getMetadataByAlias({ serviceCode: service.code, alias }) ?? {}
			return formatValue(value, meta)
		},
		/** restituisce il valore `boolean` di un METADATA con "alias" e codice del service selezionato */
		getBool(alias: string, store?: ValueStore): boolean {
			return store.state.serviceSel?.values[alias] == 1
		},
		/** restituisce il valore `number` di un piu' METADATA con un array di "alias" e codice del service selezionato*/
		getMultiByAlias(aliases: string[], store?: ValueStore): number[] {
			return aliases.map(alias => store.state.serviceSel?.values[alias])
		},
		/** restituisce un array corrispondente all'array dei SERVICES di boolean che indicano se il SERVICE è online (true) o no (false) */
		servicesIsOnline(_: void, store?: ValueStore) {
			return Object.values(store.state.services).map(service => (Date.now() - service.last) < 5000)
		},
	},

	actions: {
		/** visualizza il dettaglio di un VALUE */
		openValue(metadata: Metadata, store?: ValueStore) {
			const value = store.getByMetadata(metadata)
			store.setValueSel(value)
			store.setMetadataSel(metadata)
			store.setIsValuesOpen(true)
		},
		async close(save: boolean, store?: ValueStore) {
			if (save) {
				const meta = store.state.metadataSel
				const value = store.state.valueSel
				NATSPublish(meta.serviceCode, meta.name, value)
				dialogSo.dialogOpen({
					type: DIALOG_TYPE.INFO, modal: false,
					text: i18n.t(`snackbar.default.modify`)
				})
			}
			store.setIsValuesOpen(false)
		},

		updateValues(
			{ serviceCode, values }: { serviceCode: string, values: Values },
			store?: ValueStore
		) {
			if (!serviceCode || !values) return

			// applico al BUFFER e solo in seguito verra' smistato al relativo service. Questo per questioni di performances
			store.buffer[serviceCode] = Object.assign(store.buffer[serviceCode] ?? {}, values)

			if (store.bufferTimeout != null || natsSo.state.status == NATS_STATUS.OFFLINE) return
			store.bufferTimeout = setTimeout(() => {
				const services = { ...store.state.services };

				for (const [serviceCode, bffValues] of Object.entries(store.buffer)) {

					// trovo o creo il SERVICE con il CODE
					let service: ServiceNATS = services[serviceCode];
					if (!service) {
						service = { code: serviceCode, values: {} };
						services[serviceCode] = service;
					}
					service.last = Date.now()

					// applica i values filtrando i dati che non servono
					delete bffValues["MqttDataSource"]
					Object.assign(service.values, bffValues)
					if (service.code == store.state.serviceCodeSel) {
						store.setServiceSel({ ...service })
					}
					// verifico che ci sia il metadata
					//metaSo.update({ serviceCode, values: bffValues })
				}

				store.setServices(services)
				store.buffer = {}
				clearTimeout(store.bufferTimeout)
				store.bufferTimeout = null
			}, SET_VALUES_TIMEOUT)
		},
	},

	mutators: {
		setServices: (services: { [code: string]: ServiceNATS }) => ({ services }),
		setServiceSel: (serviceSel: ServiceNATS) => ({ serviceSel }),
		setMetadataSel: (metadataSel: Metadata) => ({ metadataSel }),
		setValueSel: (valueSel: any) => ({ valueSel }),
		setIsValuesOpen: (isValuesOpen: boolean) => ({ isValuesOpen }),
	},
}

export type ValueState = typeof setup.state
export type ValueGetters = typeof setup.getters
export type ValueActions = typeof setup.actions
export type ValueMutators = typeof setup.mutators
export interface ValueStore extends StoreCore<ValueState>, ValueGetters, ValueActions, ValueMutators {
	state: ValueState
	buffer: { [code: string]: Values }
	bufferTimeout: any
}
const valuesSo = createStore(setup) as ValueStore
valuesSo.buffer = {}
export default valuesSo

