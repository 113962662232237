
export interface SharedServices {
    host: {
        url: string
    }
    nats: Nats
    cloudNats?: Nats
    components: Component[]
}

export interface Nats {
    host?: string
    port?: number
    username?: string // skip verification if null or empty
    password?: string
    enableTls?: boolean
    responseTimeout?: number
    subscribe: string
    publish: string
}

export interface Component {
    code?: string
    name?: string
    /** il super-type a cui appartiene questo */
    group?: COMPONENT_TYPE,
    /** tags utilizzati per individuare il COMPONENT su description di un METADATA */
    tags?: string[]
    type: COMPONENT_TYPE
    disabled?: boolean
    hidden?: boolean
    subjects?: string[]
    options?: OptionsBase
}

export interface OptionsBase {
    grafana?: GrafanaOption[]
}

type GrafanaOption = {
    code: string,
    title?: string,
    url: string
}

export interface GrowUnitComponent extends Component {
    options: {
        code: string
    } & OptionsBase
}

export interface HVACComponent extends Component {
    options?: {
        number: number
    } & OptionsBase
}

export interface PowersComponent extends Component {
}

export interface GerminatorComponent extends Component {
}

export interface OsmoticWaterComponent extends Component {
    options?: {
        tank: {
            number: number,
        },
        DAB: {
            number: number,
        },
    } & OptionsBase
}


export enum COMPONENT_TYPE {
    GERMINATOR = "germinator",
    GROWUNIT = "grow-unit",
    HVAC = "hvac",
    POWERS = "powers",
    OSMOTIC_WATER = "osmotic-water",
    SERVICES = "services"
}


export function componentTypeToString (type: COMPONENT_TYPE ): string {
    return {
		[COMPONENT_TYPE.GERMINATOR]: "GERMINATOR",
		[COMPONENT_TYPE.GROWUNIT]: "GROW UNITS",
		[COMPONENT_TYPE.HVAC]: "SERVICES",
		[COMPONENT_TYPE.POWERS]: "POWERS",
        [COMPONENT_TYPE.SERVICES]: "SERVICES",
	}[type]
}